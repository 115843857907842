import { graphql, Link } from "gatsby"
import React from "react"
import SEO from '../components/seo'
import Layout from '../components/Layout'
const baseuri = "http://63.141.235.90:8091"
const baseuridemo = "http://admin.hovinx.com"
class BlogIndex extends React.Component {

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.news
    const { currentPage, numPages } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPages
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`${siteTitle}`}
          description={`${siteDescription}`}
          canonical={`https://${domainName}`}
        />
        <div className="w-grid__columns w-grid__columns--three">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <div className="w-card" key={node.slugtitle}>
                <article className="w-post-card ">
                  <div className="w-post-card__cover w-post-card__cover--with-image">
                    <Link className="w-post-card__link" tabIndex="-1" to={node.slugtitle}>
                      <figure className="w-post-card__figure">
                        <img src={baseuri + node.image} alt={title} title={title} className="w-post-card__image" />
                      </figure>
                    </Link>
                  </div>
                  <div className="w-post-card__blurb">
                    <Link className="w-post-card__link" to={node.slugtitle}>
                      <h2 className="w-post-card__headline--with-image">
                        {title}
                      </h2>
                    </Link>
                    <div className="w-authors__card">
                      <div className="w-author__image--row">
                        <div className="w-author__image--row-item">
                          <img className="w-author__image w-author__image--small" src={baseuri + node.profileImage} alt="favicon" />
                        </div>
                      </div>
                      <div>
                        <span className="w-author__name">
                          <Link to={`/authors/${node.profileSlugName}`}>{node.profileName}</Link>
                        </span>
                        <span className="w-author__name">
                          {node.categoryName !== null ? <Link to={`/category/${node.slugCategoryName}`}>{node.categoryName}</Link> : "Tin Tức Bóng Đá"}
                        </span>
                        <div className="w-author__published">
                          <time>{node.publishDate}</time>
                        </div>
                      </div>
                    </div>
                    <div className="w-post-card__desc">
                      <Link className="w-post-card__link" tabIndex="-1" to={node.slugtitle}>
                        <p className="w-post-card__subhead" dangerouslySetInnerHTML={{ __html: node.short }} />
                      </Link>
                    </div>
                  </div>
                </article>
              </div>
            )
          })}
        </div>
        <div className="w-pagination">
          <ul
          >
            {!isFirst && (
              <li className="w-pagination__link">
                <Link to={`/page/${prevPage}`} rel="prev">
                  ←
                </Link>
              </li>
            )}
            {Array.from({ length: numPages }, (_, i) => {
              if (i < curr + 3 && i > curr - 3) {
                return (
                  <li
                    key={`pagination-number${i + 1}`}
                    className="w-pagination__link"
                  >
                    <Link
                      to={`/${i === 0 ? '' : 'page/' + (i + 1)}`}
                      style={{
                        textDecoration: 'none',
                        color: i + 1 === curr ? '#000' : '',
                        background: i + 1 === curr ? '#fff' : '',
                      }}
                    >
                      {i + 1}
                    </Link>
                  </li>
                )
              }
            })}
            {!isLast && (
              <li className="w-pagination__link">
                <Link to={`/page/${nextPage}`} rel="next">
                  →
                </Link>
              </li>
            )}
          </ul>
        </div>
      </Layout>
    )
  }
}
export default BlogIndex
export const query = graphql`
query ($limit: Int = 15, $skip: Int = 0) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    news(
      limit: $limit 
      skip: $skip
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
